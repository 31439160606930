import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as text from '@/components/general/TextStyles';

export const ArticleBox = styled.div`
  width: 350px;
  height: 570px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 24px;
  overflow: hidden;
  transition: ${(props) => props.theme.transition.primary};
  position: relative;
  padding: 15px 15px 20px 15px;

  &:hover {
    transform: translate(0, -5px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.xs}px) {
    width: 100%;
    height: auto;
  }
`;

export const ArticleLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Picture = styled(GatsbyImage)`
  border-radius: 17px;
  height: 260px;
  width: 100%;

  & picture {
    & img {
      object-fit: cover;
      object-position: top;
    }
  }
`;

export const DescriptionBox = styled.div`
  padding-top: 20px;
`;

export const Title = styled.h3`
  ${text.FS24toFS20}
  overflow: hidden;
  -ms-line-clamp: 3;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;

export const TagsBox = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

interface IDescription {
  lines: number;
}

export const Description = styled.p<IDescription>`
  margin-top: 15px;
  ${text.FS15}
  color: ${(props) => props.theme.colors.grayText};
  overflow: hidden;
  -ms-line-clamp: ${(props: IDescription) => props.lines};
  -webkit-line-clamp: ${(props: IDescription) => props.lines};
  line-clamp: ${(props: IDescription) => props.lines};
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;
