export enum BlogTags {
  LATEST = 'Latest',
  NEWS = 'News',
  ENGINEERING = 'Engineering',
  TIPS_AND_TRICKS = 'Tips & Tricks',
  EXPERT_OPINIONS = 'Expert Opinions',
  PRODUCT = 'Product',
  DESIGN = 'Design',
  MORE = 'More',
}

interface IFeature {
  title: string;
  description: string;
}

export interface IArticleCard {
  date: string;
  primaryImage: any;
  title: string;
  tags: BlogTags[];
  description: {
    description: string;
  };
  tableOfContents?: string[];
  features: IFeature[];
  content: React.FC;
}
