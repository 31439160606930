import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';

import {
  Tag,
  TagAttachment,
  TagVariants,
} from '@/components/Templates/components';
import { Links } from '@/utils/constants';
import { removeSpaces } from '@/utils/functions';
import { IArticleCard } from '../../BlogArticlesInfo';

import * as styled from './Article.styled';

interface IArticle {
  article: IArticleCard;
}

export const Article: React.FC<IArticle> = ({ article }) => {
  const titleRef = useRef(null);
  const tagsRef = useRef(null);
  const [lines, setLines] = useState(0);

  useEffect(() => {
    if (titleRef?.current && tagsRef?.current) {
      const titleHeight = titleRef.current.offsetHeight;
      const tagsHeight = tagsRef.current.offsetHeight;
      const descriptionAvailableHeight =
        570 - 260 - 15 - 20 - titleHeight - tagsHeight - 15 - 15;
      const linesAvailable = Math.floor(descriptionAvailableHeight / 22.5);
      setLines(linesAvailable);
    }
  }, [titleRef, tagsRef]);

  return (
    <styled.ArticleBox>
      <styled.ArticleLink to={`${Links.BLOG}/${removeSpaces(article.title)}`} />
      <styled.Picture
        image={getImage(article.primaryImage)}
        alt={article.primaryImage.description}
      />
      <styled.DescriptionBox>
        <styled.Title ref={titleRef}>{article.title}</styled.Title>
        <styled.TagsBox ref={tagsRef}>
          {article.tags.map((tag) => (
            <Tag
              key={tag}
              attachment={TagAttachment.BLOG}
              variant={TagVariants.BLOG_SMALL}
            >
              {tag}
            </Tag>
          ))}
        </styled.TagsBox>
        <styled.Description lines={lines}>
          {article.description.description}
        </styled.Description>
      </styled.DescriptionBox>
    </styled.ArticleBox>
  );
};
