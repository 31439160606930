import styled from 'styled-components';

import { BorderLink } from '../general';

export const TopBoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-bottom: 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    max-width: 470px;
  }
`;

export const LearnMore = styled(BorderLink)`
  margin-top: 20px;
  font-size: 15px;
  line-height: 18px;
  font-weight: ${(props) => props.theme.weights.bold};
  text-align: center;

  &::before {
    bottom: -1.5px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 8px;
  }
`;

export const TagsBox = styled.div<IFixed>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 25px;
  row-gap: 8px;
  position: relative;
  z-index: 13;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    column-gap: 15px;
  }
`;

interface IFixed {
  $fixed?: boolean;
  $hideResolution?: number;
}

export const FixedTagsBox = styled.div<IFixed>`
  display: flex;
  justify-content: center;
  column-gap: 25px;
  opacity: ${(props: IFixed) => (props.$fixed ? '1' : '0')};
  pointer-events: ${(props: IFixed) => (props.$fixed ? 'auto' : 'none')};
  transform: translate(-50%, 0);
  transition: opacity 0.3s ease;
  position: fixed;
  z-index: 16;
  top: 17px;
  left: 50%;
  flex-wrap: nowrap;
  width: 700px;

  @media (max-width: ${(props) =>
      props.$hideResolution
        ? props.$hideResolution
        : props.theme.breakpoints.xl}px) {
    display: none;
  }
`;

export const TemplatesBox = styled.section`
  width: 100%;
  max-width: 1105px;
  margin: 110px auto 0;
  display: flex;
  flex-direction: column;
  row-gap: 100px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    row-gap: 80px;
    margin: 55px auto 0;
  }
`;
