import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm } from 'react-hubspot';

import nextArrow from '@/images/Blog/next-arrow.svg';
import checkMark from '@/images/Blog/check-mark.png';

import { Links } from '@/utils/constants';
import {
  TopBoxDescription,
  TagsBox,
  FixedTagsBox,
} from '@/components/Templates/Content.styled';
import { convertToNormal, removeSpaces } from '@/utils/functions';
import {
  Layout,
  InputField,
  Breadcrumbs,
  Button,
  BlurBox,
} from '@/components/general';
import * as styled from './BlogContent.styled';
import { IArticleCard, BlogTags } from './BlogArticlesInfo';
import { Article } from './components/Article';
import { Tag, TagAttachment, TagVariants } from '../Templates/components';
import { FixedContext } from '../Wrapper';
import { FeatureArticle } from './BlogArticle/components/FeatureArticle';
import { Title, UnderTitle } from '../general/FormPages.styled';

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

function fillArray(n) {
  const arr = [];
  for (let i = 1; i <= n; i++) {
    arr.push(i);
  }
  return arr;
}

interface IBlogContent {
  currentTag: BlogTags;
  data: any;
}

export const BlogContent: React.FC<IBlogContent> = ({ currentTag, data }) => {
  const [currentPaginationPage, setCurrentPaginationPage] = useState(0);

  const { articles } = data.contentfulBlogPage;

  const blog = data.contentfulBlogPage;

  const tagToFilter = convertToNormal(currentTag);

  let filteredArticels: IArticleCard[];

  if (currentTag) {
    filteredArticels = articles.filter((article) =>
      article.tags.includes(tagToFilter as BlogTags),
    );
  } else {
    filteredArticels = articles;
  }

  const slicedArticles = sliceIntoChunks(filteredArticels, 6);

  const pageNumbers = Math.round(slicedArticles.length / 2);

  const pagesArr = fillArray(pageNumbers);

  const paginatinBoxRef = useRef(null);
  const [ml, setMl] = useState(0);

  useEffect(() => {
    setMl(paginatinBoxRef?.current?.offsetWidth / 2);
  }, [paginatinBoxRef]);

  const offset = -15;

  const onPageNumberClick = (num: number, element: HTMLElement) => {
    setCurrentPaginationPage(num - 1);
    const y = element.offsetTop + offset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };
  const onNextClick = (element: HTMLElement) => {
    if (currentPaginationPage + 1 < pageNumbers) {
      setCurrentPaginationPage(currentPaginationPage + 1);
      const y = element.offsetTop + offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const ArticlesTopRef = useRef(null);

  const tagsBox = useRef(null);

  const { isFixed, setIsFixed } = useContext(FixedContext);

  useEffect(() => {
    const addFixed = () => {
      if (window.innerWidth > 1022) {
        if (scrollY > tagsBox?.current?.offsetTop + 45) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      } else {
        setIsFixed(false);
      }
    };
    addFixed();
    window.addEventListener('scroll', addFixed);
    return () => window.removeEventListener('scroll', addFixed);
  }, [tagsBox, tagsBox.current]);

  let FIXED_TAGS: BlogTags[] = [];
  let REST_TAGS: BlogTags[] = [];
  const MAX_BLOG_TAGS = 3;
  if (blog.tags.length > MAX_BLOG_TAGS) {
    FIXED_TAGS = blog.tags.slice(0, MAX_BLOG_TAGS - 1);
    FIXED_TAGS.push(BlogTags.MORE);
    REST_TAGS = blog.tags.slice(MAX_BLOG_TAGS - 1, blog.tags.length);
  } else {
    FIXED_TAGS = blog.tags;
  }

  const formData = useForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM_ID,
  });

  const articlesEnoughToFillUnderBlueBox =
    slicedArticles[currentPaginationPage * 2 + 1] &&
    slicedArticles[currentPaginationPage * 2 + 1].length !== 0;

  return (
    <Layout>
      <Breadcrumbs links={[Links.BLOG]} />
      <BlurBox $containTagsBox>
        <TopBoxDescription>
          <Title>{blog.title}</Title>
          <UnderTitle>{blog.description.description}</UnderTitle>
        </TopBoxDescription>
        <div id="tags-box">
          <TagsBox ref={tagsBox}>
            <Tag
              $active={!currentTag}
              allTag
              variant={TagVariants.BIG}
              attachment={TagAttachment.BLOG}
            >
              {BlogTags.LATEST}
            </Tag>
            {blog.tags.map((tag: string) => (
              <Tag
                key={tag}
                $active={removeSpaces(tag) === currentTag}
                variant={TagVariants.BIG}
                attachment={TagAttachment.BLOG}
              >
                {tag}
              </Tag>
            ))}
          </TagsBox>
          <FixedTagsBox $fixed={isFixed} $hideResolution={1023}>
            <Tag
              $active={!currentTag}
              $fixedTagBox
              fixedTime={0.25}
              $fixed={isFixed}
              allTag
              attachment={TagAttachment.BLOG}
              variant={TagVariants.BIG}
            >
              {BlogTags.LATEST}
            </Tag>
            {FIXED_TAGS.map((tag, index) => (
              <Tag
                key={tag}
                $active={removeSpaces(tag) === currentTag}
                $fixedTagBox
                fixedTime={(index + 2) / 4}
                $fixed={isFixed}
                restTags={tag === BlogTags.MORE && REST_TAGS}
                variant={
                  tag === BlogTags.MORE ? TagVariants.DROPDOWN : TagVariants.BIG
                }
                attachment={TagAttachment.BLOG}
                currentTag={currentTag}
              >
                {tag}
              </Tag>
            ))}
          </FixedTagsBox>
        </div>
      </BlurBox>
      <FeatureArticle
        $isBlog
        title={blog.feature.title}
        description={blog.feature.description.description}
        tags={blog.feature.tags}
        imageUrl={blog.feature.primaryImage.url}
      />
      <styled.ArticlesSection>
        {slicedArticles.length === 0 ? (
          <i>No articles with this tag for now</i>
        ) : (
          <styled.ArticlesGrid ref={ArticlesTopRef}>
            {slicedArticles[currentPaginationPage * 2]?.map((article) => (
              <Article key={article.id} article={article} />
            ))}
          </styled.ArticlesGrid>
        )}
        {!formData.data ? (
          <styled.BlueBox
            $articlesEnoughToFillUnderBlueBox={articlesEnoughToFillUnderBlueBox}
          >
            <styled.BlueContent>
              <styled.BlueBoxTextBox>
                <styled.BlueBoxTitle>
                  Subscribe to new posts
                </styled.BlueBoxTitle>
                <styled.BlueBoxText>
                  Get updates on the newest features, tips and how-tos delivered
                  to your inbox.
                </styled.BlueBoxText>
              </styled.BlueBoxTextBox>
              <styled.FormBox id="subscribe" onSubmit={formData.handleSubmit}>
                <InputField type="email" name="email" required>
                  Enter your email address
                </InputField>
                <Button type="submit">Subscribe</Button>
              </styled.FormBox>
            </styled.BlueContent>
            <styled.BlueBlurEllipse />
          </styled.BlueBox>
        ) : (
          <styled.BlueBox
            style={{ justifyContent: 'center', padding: '33.5px 20px' }}
            $articlesEnoughToFillUnderBlueBox={articlesEnoughToFillUnderBlueBox}
          >
            <styled.BlueContent>
              <styled.BlueBoxTextBox
                style={{ maxWidth: '466px', textAlign: 'center' }}
              >
                <styled.CheckImage src={checkMark} alt="success" />
                <styled.BlueBoxTitle>
                  Get ready to become even more tech-savvy.
                </styled.BlueBoxTitle>
                <styled.BlueBoxText style={{ maxWidth: 'none' }}>
                  You can always unsubscribe if you get bored.
                </styled.BlueBoxText>
              </styled.BlueBoxTextBox>
            </styled.BlueContent>
            <styled.BlueBlurEllipse />
          </styled.BlueBox>
        )}
        {articlesEnoughToFillUnderBlueBox && (
          <styled.ArticlesGrid>
            {slicedArticles[currentPaginationPage * 2 + 1]?.map((article) => (
              <Article key={article.id} article={article} />
            ))}
          </styled.ArticlesGrid>
        )}
      </styled.ArticlesSection>
      {pageNumbers > 1 && (
        <styled.PaginationBox ref={paginatinBoxRef} ml={ml}>
          <styled.Pagination>
            {pagesArr?.map((num) => (
              <styled.PageNubmer
                $active={currentPaginationPage + 1 === num}
                key={num}
                onClick={() => onPageNumberClick(num, ArticlesTopRef?.current)}
              >
                {num}
              </styled.PageNubmer>
            ))}
          </styled.Pagination>
          <styled.NextBox onClick={() => onNextClick(ArticlesTopRef?.current)}>
            <styled.Next>Next</styled.Next>
            <styled.NextArrow src={nextArrow} />
          </styled.NextBox>
        </styled.PaginationBox>
      )}
    </Layout>
  );
};
