import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';
import * as input from '@/components/general/InputField/InputField.styled';

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArticlesSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding-top: 30px;
  }
`;

export const ArticlesGrid = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 23px;
  row-gap: 30px;
`;

export const BlueBox = styled.div<{
  $articlesEnoughToFillUnderBlueBox?: boolean;
}>`
  max-width: 1105px;
  width: 100%;
  min-height: 245px;
  display: flex;
  align-items: center;
  border-radius: 31px;
  margin: ${(props) =>
    props.$articlesEnoughToFillUnderBlueBox ? '80px 0' : '80px 0 0 0'};
  background-color: ${(props) => props.theme.colors.blue};
  padding: 57px 70px;
  position: relative;
  z-index: 0;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 50px 15px 50px 15px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    padding-bottom: 25px;
  }
`;

export const BlueContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  position: relative;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column;
  }
`;

export const BlueBlurEllipse = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 1105px;
  aspect-ratio: 1/0.33;
  position: absolute;
  pointer-events: none;
  left: calc(280 / 1440 * 100vw);
  bottom: -25%;
  transform: rotate(-23.71deg);
  border-radius: 50%;
  filter: blur(150px);
  will-change: transform;
  opacity: 0.4;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    left: 50%;
    top: 50%;
    transform: translate(-30%, -50%) rotate(-23.71deg);
  }
`;

export const BlueBoxTextBox = styled.div``;

export const CheckImage = styled.img`
  width: 50px;
  margin-bottom: 20px;
`;

export const BlueBoxTitle = styled.h2`
  ${text.FS24toFS20}
  color: ${(props) => props.theme.colors.white};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    text-align: center;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 24px;
  }
`;

export const BlueBoxText = styled.p`
  ${text.FS17_NOT_CHANGE}
  margin-top: 20px;
  color: ${(props) => props.theme.colors.offWhite};
  max-width: 320px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    text-align: center;
    margin: 15px auto 0 auto;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const FormBox = styled.form`
  display: flex;
  column-gap: 15px;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 35px;
    padding: 0 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
    max-width: 310px;
  }

  ${input.InputBox} {
    margin-bottom: 0px;
    min-width: 380px;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
      min-width: 0px;
      width: 100%;
    }

    & ${input.Input} {
      ${text.FS14}
      height: 50px;

      @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
        font-size: 15px;
        text-align: center;
      }
    }
  }
  & button {
    height: 50px;
    border-radius: 16px;
    min-width: 135px;
    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      width: 100%;
      min-width: 0px;
    }
  }
`;

export const Input = styled.input`
  font-weight: ${(props) => props.theme.weights.regular};
  background: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.black};
  width: 332px;
  height: 50px;
  border-radius: 100px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.004em;
  color: ${(props) => props.theme.colors.grayText};

  &:focus {
    color: ${(props) => props.theme.colors.black};
    font-weight: ${(props) => props.theme.weights.bold};
    &::placeholder {
      font-weight: ${(props) => props.theme.weights.regular};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: calc(
      332px - (${(props) => props.theme.breakpoints.xl}px - 100vw) / 4
    );
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: 332px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
    text-align: center;
  }
`;

interface IPaginationBox {
  ml: number;
}

export const PaginationBox = styled.div<IPaginationBox>`
  display: flex;
  align-items: center;
  column-gap: 38px;
  margin-top: 100px;
  margin-left: ${(props: IPaginationBox) => props.ml}px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-left: 0;
  }
`;

export const Pagination = styled.div`
  display: flex;
  column-gap: 6px;
`;

interface IPagaNumber {
  $active?: boolean;
}

export const PageNubmer = styled.span<IPagaNumber>`
  font-size: 18px;
  line-height: 0;
  font-weight: ${(props) => props.theme.weights.regular};
  background-color: ${(props) =>
    props.$active ? props.theme.colors.blue : 'transperent'};
  color: ${(props) =>
    props.$active ? props.theme.colors.white : props.theme.colors.black};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const Next = styled.span`
  font-weight: ${(props) => props.theme.weights.regular};
  font-size: 18px;
  line-height: 145%;
`;

export const NextArrow = styled.img`
  margin-left: 8px;
  margin-top: 2px;
  width: 20px;
  transition: ${(props) => props.theme.transition.primary};
`;

export const NextBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${NextArrow} {
      transform: translate(5px, 0);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    display: none;
  }
`;
